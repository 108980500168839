/** @jsx jsx */
import gql from "graphql-tag";
import { useQuery } from "urql";
import { Link } from "react-router-dom";
import { Styled, jsx, Flex, Button, Box } from "theme-ui";
import * as React from "react";

const ARTICLES = gql`
  query HomeArticles {
    articles(first: 3) {
      id
      title
      intro
      slug
    }
  }
`;

const Articles: React.FC = () => {
  const [result] = useQuery({
    query: ARTICLES
  });

  if (result.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <Flex
          sx={{
            justifyContent: "space-between",
            pt: 3,
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: "secondary"
          }}
        >
          <Styled.h2 sx={{ display: "inline-flex" }}>Articles</Styled.h2>
          {/* <Styled.a as={props => <Link {...props} to="/articles" />}>
            Show all
          </Styled.a> */}
        </Flex>
        {result.data.articles.map(article => (
          <article key={article.id}>
            <Styled.h3>{article.title}</Styled.h3>
            <Styled.p>{article.intro}</Styled.p>
            <Styled.a
              as={props => <Link {...props} to={`/articles/${article.slug}`} />}
            >
              Read more →
            </Styled.a>
          </article>
        ))}
      </Box>
    );
  }

  return null;
};

export default Articles;
