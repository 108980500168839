/** @jsx jsx */
import * as React from "react";
import { jsx, Box, Container, Styled } from "theme-ui";

const gradient = t =>
  `linear-gradient(to right, ${t.colors.primary}, ${t.colors.secondary})`;

const Head: React.FC = props => {
  return (
    <Box
      sx={{
        mb: 3,
        background: gradient
      }}
    >
      <Container pt={5} pb={1} px={4}>
        <Styled.h1 sx={{ color: "inverted.text" }}>{props.children}</Styled.h1>
      </Container>
    </Box>
  );
};

export default Head;
