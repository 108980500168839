/** @jsx jsx */
import * as React from "react";
import { jsx, Container, Styled } from "theme-ui";
import { Helmet } from "react-helmet-async";

import Head from "../Head";
import Articles from "./Articles";
import SocialLinks from "../SocialLinks";

const Home: React.FC = () => {
  return (
    <div>
      <section id="main">
        <Helmet>
          <title>Steven Langbroek</title>
        </Helmet>
        <Head>
          Hi, I'm Steven Langbroek, and I develop well-crafted and maintainable
          web applications. I live and work in Berlin, where I have:
        </Head>
        <Container px={4}>
          <Styled.ul>
            {[
              "managed several engineering teams",
              "written code that is used by engineers and end-users alike",
              "given training and presentations",
              "written documentation, articles and advocated for solid development principles",
              "reviewed code, abstracted common use cases into libraries, and driven adoption of external ones.",
              "eaten exquisite 'burgers",
              "drank beers, cocktails and fine Riesling (Friday only)"
            ].map(s => (
              <Styled.li key={s}>{s}</Styled.li>
            ))}
          </Styled.ul>
        </Container>
      </section>
      <section id="contact">
        <Container px={4} mb={1}>
          <Articles />
          <SocialLinks />
        </Container>
      </section>
    </div>
  );
};

export default Home;
